<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <path d="M3 12H21" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M3 6H21" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M3 18H21" stroke-linecap="square" stroke-linejoin="round" />
  </svg>
</template>
